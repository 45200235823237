import React, { useState, useEffect } from "react"
import Menu from "../components/Menu"
import { faqs, plans } from "../utils/helpers"
import { FaXTwitter, FaFacebook } from "react-icons/fa6"
import { PiInstagramLogoFill } from "react-icons/pi"
import { FaLinkedin } from "react-icons/fa"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { scroller } from "react-scroll"



const LandingPage = () => {
  const location = useLocation()
  const [billingPeriod, setBillingPeriod] = useState("monthly");

  useEffect(() => {
      const hash = location.hash
      if (hash) {
          const id = hash.replace("#", "")
          scroller.scrollTo(id, {
              duration: 800,
              delay: 0,
              smooth: "easeInOutQuart",
          })
      }
  }, [location])
    const [openIndex, setOpenIndex] = useState(null)
    const toggleFAQ = (index) => {
        setOpenIndex(index === openIndex ? null : index)
    }
    return (
        <div className="min-h-screen">
            {/* Menu */}
            <Menu />

            {/* Section 1: Hero */}
            <section
                className="relative w-full bg-cover bg-center bg-no-repeat mt-16 md:mt-24"
                style={{
                    backgroundImage: "url('/assets/images/hero_bg_image.png')",
                }}
            >
                <div className="w-full h-full absolute top-0 left-0"></div>

                <div className="container mx-auto px-4 py-12 md:py-16 relative z-10">
                    {/* Text Content */}
                    <div className="text-center max-w-3xl mx-auto">
                        <h1
                            className="font-bricolage font-semibold text-[32px] leading-[40px] md:text-[46.4px] md:leading-[56px] text-center"
                            style={{
                                background:
                                    "linear-gradient(180deg, #2D3142 0%, #2A9D8F 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Effortlessly forecast short-term <br />
                            Market Trends
                        </h1>

                        <p
                            className="font-dmSans font-bold text-[16px] leading-[24px] md:text-[19.3339px] md:leading-[150%] text-[#2D3142] text-center mt-4 mx-auto"
                            style={{ maxWidth: "90%", md: "700.85px" }}
                        >
                            Track market trends, explore key
                            insights, and make confident decisions—all in one
                            simple platform.
                        </p>
                        <Link to="/dashboard">
                            <button className="mt-6 bg-[#2A9D8F] text-white font-dmSans font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600">
                                Start Forecasting
                            </button>
                        </Link>
                    </div>

                    {/* App Dashboard Image */}
                    <div className="mt-8 md:mt-12">
                        <img
                            src="/assets/images/app_dashboard.png"
                            alt="App Dashboard"
                            className="mx-auto max-w-full"
                        />
                    </div>
                </div>
            </section>

            {/* Section 2: */}
            <section className="py-20 bg-white">
                <div className="container mx-auto px-4 md:px-8 lg:px-44">
                    {/* Heading */}
                    <div className="text-center mb-12">
                        <h2
                            className="font-bricolage font-semibold text-[28px] leading-[34px] md:text-[35px] md:leading-[42px] text-center"
                            style={{
                                background:
                                    "linear-gradient(180deg, #2D3142 0%, #1D7B6F 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Simplify Your Market Analysis in <br />3 Easy Steps
                        </h2>
                    </div>

                    {/* Content Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-[2fr,3fr] gap-6 md:gap-12 items-stretch">
                        {/* Left Column */}
                        <div className="bg-[rgba(235,250,250,0.6)] rounded-[23.2006px] p-6 md:p-8 text-left">
                            <img
                                src="/assets/images/searchcard_image.png"
                                alt="Search Card"
                                className="rounded-lg shadow-md w-full max-w-xs md:max-w-sm mb-6"
                            />
                            <h3 className="font-bricolage font-bold text-[24px] leading-[30px] md:text-[30.9342px] md:leading-[37px] text-[#2D3142]">
                                Search for a Ticker
                            </h3>
                            <p className="font-dmSans font-medium text-[16px] leading-[22px] md:text-[23.2006px] md:leading-[30px] text-[#2D3142] mt-4">
                                Enter any stock or asset symbol into the search
                                bar to get started.
                            </p>
                        </div>

                        {/* Right Column */}
                        <div className="flex flex-col space-y-8 md:space-y-12">
                            {/* Card 1 */}
                            <div className="flex flex-col md:flex-row items-center bg-[rgba(254,243,239,0.6)] rounded-[23px] p-4 md:p-6">
                                <img
                                    src="/assets/images/market_trends.svg"
                                    alt="Market Trends"
                                    className="rounded-md w-full md:w-1/3 h-auto object-cover mb-4 md:mb-0 md:mr-6"
                                />
                                <div>
                                    <h3 className="font-bricolage font-bold text-[20px] leading-[26px] md:text-[23.2006px] md:leading-[28px] text-[#2D3142]">
                                        Explore Market Trends
                                    </h3>
                                    <p className="font-dmSans font-medium text-[14px] leading-[18px] md:text-[15.4671px] md:leading-[20px] text-[#2D3142] mt-2">
                                        Instantly access organized and
                                        actionable insights tailored to your
                                        ticker.
                                    </p>
                                </div>
                            </div>

                            {/* Card 2 */}
                            <div className="flex flex-col md:flex-row items-center bg-[rgba(245,241,254,0.6)] rounded-[23px] p-4 md:p-6">
                                <img
                                    src="/assets/images/next_move.svg"
                                    alt="Next Move"
                                    className="rounded-md w-full md:w-1/3 h-auto object-cover mb-4 md:mb-0 md:mr-6"
                                />
                                <div>
                                    <h3 className="font-bricolage font-bold text-[20px] leading-[26px] md:text-[23.2006px] md:leading-[28px] text-[#2D3142]">
                                        Plan Your Next Move
                                    </h3>
                                    <p className="font-dmSans font-medium text-[14px] leading-[18px] md:text-[15.4671px] md:leading-[20px] text-[#2D3142] mt-2">
                                        Use the data to confidently make
                                        informed decisions and stay ahead of the
                                        curve.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Call-to-Action Button */}
                    <div className="mt-12 text-center">
                    <Link 
                        to="/signup" 
                        className="bg-[#2A9D8F] text-white font-dmSans font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600">
                            Explore trends and stay ahead
                        </Link>
                    </div>
                </div>
            </section>

            {/* Section 3 */}
            <section className="py-20 bg-[#F3FCFC]">
                <div className="container mx-auto px-4 md:px-8 lg:px-36">
                    {/* Left Column */}
                    <div className="grid grid-cols-1 md:grid-cols-[2fr,3fr] gap-12 items-start">
                        {/* Text Section */}
                        <div className="text-left">
                            <h2
                                className="font-bricolage font-semibold text-[35px] leading-[42px] mb-6"
                                style={{
                                    background:
                                        "linear-gradient(180deg, #2D3142 0%, #2A9D8F 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}
                            >
                                Powerful Features to <br /> Elevate Your{" "}
                                <span>Market Analysis</span>
                            </h2>
                            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142] mb-6">
                                Explore the tools and insights that make Trenviz
                                the go-to platform for informed decision-making
                                in the market.
                            </p>
                            <Link 
                                to="/signup" 
                                className="bg-[#2A9D8F] text-white font-dmSans font-semibold text-[15px] px-6 py-3 rounded-[32px] shadow-md hover:bg-teal-600">
                                Get Started
                            </Link>
                        </div>

                        {/* Feature Cards */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                            {/* Card 1 */}
                            <div className="bg-white rounded-[23px] shadow-md p-6">
                                <img
                                    src="/assets/images/probabilistic_model_icon.png"
                                    alt="Advanced Probabilistic Model"
                                    className="w-14 h-14 mb-4"
                                />
                                <h3 className="font-bricolage font-bold text-[18px] leading-[28px] text-[#2D3142]">
                                    Advanced and Enhanced Probabilistic Model
                                </h3>
                                <p className="font-dmSans font-medium text-[12px] leading-[22px] text-[#2D3142] mt-2">
                                    Trenviz uses sophisticated models to
                                    forecast potential market movements,
                                    providing more reliable insights for
                                    decision-making.
                                </p>
                            </div>

                            {/* Card 2 */}
                            <div className="bg-white rounded-[23px] shadow-md p-6 relative sm:-top-8">
                                <img
                                    src="/assets/images/market_regime_icon.png"
                                    alt="Market Regime Detection"
                                    className="w-14 h-14 mb-4"
                                />
                                <h3 className="font-bricolage font-bold text-[18px] leading-[28px] text-[#2D3142]">
                                    Market Regime Detection
                                </h3>
                                <p className="font-dmSans font-medium text-[12px] leading-[22px] text-[#2D3142] mt-2">
                                    Trenviz detects market regimes, adjusting
                                    its analysis based on the current market
                                    environment (e.g., bull or bear markets).
                                </p>
                            </div>

                            {/* Card 3 */}
                            <div className="bg-white rounded-[23px] shadow-md p-6">
                                <img
                                    src="/assets/images/confidence_intervals_icon.png"
                                    alt="Confidence Intervals"
                                    className="w-20 h-12 mb-4"
                                />
                                <h3 className="font-bricolage font-bold text-[18px] leading-[28px] text-[#2D3142]">
                                    Confidence Intervals for Risk Assessment
                                </h3>
                                <p className="font-dmSans font-medium text-[12px] leading-[22px] text-[#2D3142] mt-2">
                                    Trenviz offers a quantifiable measure of
                                    risk associated with each prediction,
                                    allowing users to assess potential outcomes.
                                </p>
                            </div>

                            {/* Card 4 */}
                            <div className="bg-white rounded-[23px] shadow-md p-6 relative sm:-top-8">
                                <img
                                    src="/assets/images/technical_support_icon.png"
                                    alt="Technical Support Detection"
                                    className="w-14 h-14 mb-4"
                                />
                                <h3 className="font-bricolage font-bold text-[18px] leading-[28px] text-[#2D3142]">
                                    Detects Technical Support/Resistance Levels
                                </h3>
                                <p className="font-dmSans font-medium text-[12px] leading-[22px] text-[#2D3142] mt-2">
                                    Trenviz identifies technical levels that
                                    indicate potential price barriers, helping
                                    traders make informed decisions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section 4 */}
            <section className="bg-white py-16 md:py-24 text-[#2D3142] flex justify-center">
                <div className="container  mx-auto px-4  lg:px-20 text-center ">
                    <h2 className="text-3xl font-bold  font-bricolage mb-2">
                        Why Trenviz?
                    </h2>
                    <p className="font-dmSans text-md">
                        The Smarter Way to Stay on Top of Market Trends
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 mt-8 text-left w-full gap-10 lg:gap-14 mt-4">
                        <div>
                            <img
                                src="/assets/images/easy_to_use.svg"
                                alt="Easy to Use"
                                className=" w-full  object-cover"
                            />
                            <h2 className="text-[20px] sm:text-xl text font-bricolage my-4 text-[#2D3142] font-bold">
                                Easy to Use
                            </h2>
                            <p className="text-md font-dmSans text-[#2D3142] font-medium">
                                Navigate effortlessly and find the insights you
                                need in seconds
                            </p>
                        </div>
                        <div className="mt-10 md:mt-0">
                            <img
                                src="/assets/images/time_saving.svg"
                                alt="Time-Saving"
                                className=" w-full object-cover"
                            />
                            <h2 className="text-[20px] sm:text-xl font-bricolage my-4 font-bold">
                                Time-Saving
                            </h2>
                            <p className="text-md font-dmSans text-[#2D3142] font-medium">
                                Quickly access organized trends without digging
                                through data.
                            </p>
                        </div>
                        <div className="mt-10 md:mt-0">
                            <img
                                src="/assets/images/focused_insights.svg"
                                alt="Focused Insight "
                                className="w-full  object-cover"
                            />
                            <h2 className="text-[20px] sm:text-xl font-bricolage my-4 font-bold">
                                Focused Insights
                            </h2>
                            <p className="text-md font-dmSans text-[#2D3142] font-medium">
                                See only the information that matters most to
                                your decisions.
                            </p>
                        </div>
                    </div>
                    <div className="mt-12">
                    <Link 
                        to="/signup"  
                        className="bg-[#2A9D8F] text-white font-dmSans font-semibold font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600 mt-10">
                        Try Trenviz
                    </Link>
                    </div>

                </div>
            </section>
            {/* FAQ Section  */}
            <section id="faqs" className="bg-[#F7FCFC] py-16 md:py-20 text-black-100 flex justify-center">
                <div className="container  mx-auto px-4  lg:px-20 ">
                    <h2 className="text-3xl font-bold  font-bricolage text-center mb-2">
                        Frequently Asked Questions
                    </h2>
                    <p className="font-dmSans text-md text-center mb-6 text-[19.33px] md:w-3/4 mx-auto leading-[23.2px] text-[#17171799] ">
                        Here are answers to some of our frequently asked questions.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-6">
                        {faqs.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-md cursor-pointer"
                                onClick={() => toggleFAQ(index)}
                            >
                                <div className="flex justify-between items-center">
                                    <h3 className="font-dmSans text-[#2C3E50] md:text-[24.17px] sm:text-[20px] leading-[24px] text-[16px] md:leading-[29px] leading-[19.2px]">
                                        {faq.question}
                                    </h3>
                                    <button
                                        className={`text-2xl bg-[#2C3E501A] rounded-[7px] text-[#6F6C90] p-2 py-0 h-fit transform transition-transform ${
                                            openIndex === index
                                                ? "rotate-45"
                                                : "rotate-0"
                                        }`}
                                    >+
                                    </button>
                                </div>
                                {openIndex === index && (
                                    <p className="mt-4 text-gray-600">
                                        {faq.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
           
           {/* Section 6 */}
           <section
  id="pricing"
  className="bg-white py-16 md:py-24 text-black-100 flex justify-center"
>
  <div className="container mx-auto px-4 lg:px-20">
    <h2 className="text-3xl font-bold font-bricolage text-center mb-2">
      Choose the Plan That Works for You
    </h2>
    <p className="font-dmSans text-md text-center mb-6 text-2lg text-[#2D3142]">
      Trenviz offers flexible pricing to suit your needs,
      whether you're an individual investor or a professional.
    </p>

    {/* Toggle for Monthly/Yearly */}
    <div className="flex justify-center items-center mb-8">
      <button
        onClick={() => setBillingPeriod('monthly')}
        className={`px-4 py-2 text-sm font-medium ${
          billingPeriod === 'monthly'
            ? 'bg-[#2A9D8F] text-white'
            : 'bg-gray-200 text-black'
        } rounded-l-full`}
      >
        Monthly
      </button>
      <button
        onClick={() => setBillingPeriod('yearly')}
        className={`px-4 py-2 text-sm font-medium ${
          billingPeriod === 'yearly'
            ? 'bg-[#2A9D8F] text-white'
            : 'bg-gray-200 text-black'
        } rounded-r-full`}
      >
        Yearly
      </button>
    </div>

    {/* Pricing Cards */}
    <div className="flex flex-wrap justify-center items-center gap-8">
      {plans
        .filter((plan) => plan.billing === billingPeriod)
        .map((plan, index) => (
          <div
            key={index}
            className="bg-white p-6 font-outfit shadow-md rounded-lg w-full max-w-sm mx-auto"
          >
            <h3 className="font-semibold text-[#2D3142] text-[24px] sm:text-[26px] md:text-[29.97px] leading-[35.96px] mb-2">
              {plan.title}
            </h3>
            <p className="text-[#17171780] mb-4 text-[15.47px] leading-[18.56px]">
              {plan.description}
            </p>
            <div className="flex items-start mb-6">
              <div className="text-[28px] md:text-[37.7px] leading-[45.24px] font-bold text-gray-900">
                {plan.price}
              </div>
              <div className="text-black text-md ml-2">
                {plan.period
                  .split(' ')
                  .map((word, idx) => (
                    <p key={idx}>{word}</p>
                  ))}
              </div>
            </div>
            <p className="mt-6 mb-8 text-[#04081580]">{plan.features}</p>
            <p className="text-sm text-[#2A9D8F] mb-4">
              Includes a 7-day free trial. No card required!
            </p>
            <Link
              to="/signup"
              className="bg-[#2A9D8F] text-white text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-full w-full hover:bg-teal-600 transition"
            >
              Start Free Trial
            </Link>
          </div>
        ))}
    </div>
  </div>
</section>





            {/* Call to Action Section */}
            <section className="bg-gray-50 pt-12 md:pt-16">
                <div className="  flex flex-col md:flex-row items-center  ">
                    {/* Text Content */}
                    <div className="md:w-1/2 text-left mb-8 md:mb-0 px-6 md:px-8 lg:px-12">
                        <h2 className="text-2lg font-dmSans text-black-100 mb-4">
                            Ready to Make Smarter Market Moves
                        </h2>
                        <p className="text-black-100 text-xl font-bold  font-bricolage mb-6">
                            Start using Trenviz today to gain valuable insights
                            and take control of your trading decisions.
                        </p>
                        <Link 
                            to="/signup"  
                            className="bg-[#2A9D8F] text-white font-dmSans font-medium text-[15px] px-6 py-3 rounded-[32px] shadow-md hover:bg-teal-600 mb-4">
                            Get Started
                        </Link>
                    </div>

                    {/* Image */}
                    <div className="md:w-1/2 px-4  md:pr-0 flex  justify-end">
                        <img
                            src="/assets/images/app_dashboard_interface.png" // Replace with the actual image URL
                            alt="Trenviz app interface"
                            className="rounded-lg shadow-md "
                        />
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="bg-[#1B1C1E] text-white py-12 md:py-20">
                <div className="max-w-7xl mx-auto px-6 md:px-16 grid grid-cols-1 md:grid-cols-3 md:gap-10 lg:gap-20 ">
                    {/* Branding */}
                    <div className="md:block hidden">
                        <div className="mb-6">
                        <Link to="/">
                            <img
                                src="/assets/images/trenviz_logo.png"
                                alt="Trenviz Logo"
                                className="h-8"
                            />
                            </Link>
                        </div>
                        <div className="flex space-x-6">
                            {/* Social Icons */}
                            <a href="/" className="text-white hover:text-white">
                                <FaXTwitter size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <FaFacebook size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <PiInstagramLogoFill size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <FaLinkedin size="22px" />
                            </a>
                        </div>
                    </div>

                    {/* Mailing List */}
                    <div>
                        <h4 className="text-lg font-bold mb-4 font-bricolage">
                            Mailing List
                        </h4>
                        <p className="text-[#2A9D8F] font-dmSans mb-4">
                            Sign up to our mailing list to get the latest
                            updates about Trenviz.
                        </p>
                        <div className="fle">
                            <div className="bg-white text-[#000000] rounded-full overflow-hidden">
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="w-full px-4 py-2 text-sm focus:outline-none h-10"
                                />
                            </div>
                            <button className="bg-teal-500 text-white rounded-full  py-2 sm:py-3 px-16 mt-4 hover:bg-teal-600">
                                Subscribe
                            </button>
                        </div>
                    </div>

                    {/* Links */}
                    <div className="md:pl-20 pt-10 md:pt-0 font-dmSans">
                        <ul className="space-y-2">
                            <li>
                                <a
                                    href="/faq"
                                    className="text-white hover:text-white"
                                >
                                    FAQ
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    className="text-white hover:text-white"
                                >
                                    TERMS & CONDITIONS
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/privacy"
                                    className="text-white hover:text-white"
                                >
                                    PRIVACY POLICY
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/pricing"
                                    className="text-white hover:text-white"
                                >
                                    PAYMENT INFO
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/"
                                    className="text-white hover:text-white"
                                >
                                    CONTACT US
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="md:hidden block mt-10">
                        <div className="mb-6">
                            <img
                                src="/assets/images/trenviz_logo.png"
                                alt="Trenviz Logo"
                                className="h-8"
                            />
                        </div>
                        <div className="flex space-x-6">
                            {/* Social Icons */}
                            <a href="/" className="text-white hover:text-white">
                                <FaXTwitter size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <FaFacebook size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <PiInstagramLogoFill size="22px" />
                            </a>
                            <a href="/" className="text-white hover:text-white">
                                <FaLinkedin size="22px" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-10 mx-auto md:mx-48 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm text-center md:text-left mb-4 md:mb-0">
          &copy; {new Date().getFullYear()} Trenviz. All rights reserved.
        </p>
        <div className="flex space-x-4">
          <Link
            to="/terms"
            className="text-sm hover:underline"
          >
            Terms of Service
          </Link>
          <Link
            to="/privacy"
            className="text-sm hover:underline"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
            </footer>
        </div>
    )
}

export default LandingPage
