import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  return authState.isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
