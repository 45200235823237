import React, { useState } from "react";
import { plans } from "../utils/helpers";
import Layout from "../components/Layout/layout";
import axios from "axios";

const UpgradePage = () => {
  const [billingCycle, setBillingCycle] = useState("monthly"); // 'monthly' or 'yearly'

  const handleUpgrade = async (planId) => {
    try {
      console.log("Selected Plan ID (Frontend):", planId); // Debugging log
      console.log("Stripe Public Key:", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // Debugging log

      // Initialize Stripe
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) {
        throw new Error("Stripe is not properly initialized. Check your API key.");
      }

      // Make API call to create a checkout session
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/create-checkout-session`,
        { planId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error("Error upgrading plan:", error.response?.data || error.message);
      alert("Failed to initiate the subscription. Please try again.");
    }
  };

  return (
    <Layout title="Upgrade Your Plan">
      <div
        className="bg-white py-16 md:py-24 text-black-100 flex flex-col items-center justify-center"
        style={{
          backgroundImage: "url('/assets/images/hero_bg_image.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <div className="text-center max-w-screen-lg mx-auto w-full">
          <h2 className="text-3xl font-bold font-bricolage text-[#2A9D8F] mb-4">
            Upgrade Your Financial Predictions
          </h2>
          <p className="font-dmSans text-md text-[#2D3142] mb-8">
            Unlock powerful simulation tools tailored for your financial insights.
          </p>

          {/* Billing Cycle Toggle */}
          <div className="flex justify-center mb-10">
            <label className="inline-flex items-center">
              <span className={`mr-2 ${billingCycle === "monthly" ? "font-bold" : ""}`}>
                Monthly
              </span>
              <div
                className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in"
                onClick={() =>
                  setBillingCycle(billingCycle === "monthly" ? "yearly" : "monthly")
                }
              >
                <div
                  className={`toggle-dot absolute w-5 h-5 bg-teal-500 rounded-full shadow inset-y-0 left-0 transition-transform duration-300 transform ${
                    billingCycle === "yearly" ? "translate-x-full" : ""
                  }`}
                ></div>
                <div className="block bg-gray-300 w-10 h-5 rounded-full"></div>
              </div>
              <span className={`ml-2 ${billingCycle === "yearly" ? "font-bold" : ""}`}>
                Yearly
              </span>
            </label>
          </div>

          {/* Plans */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-screen-xl mx-auto justify-items-center">
            {plans
              .filter((plan) => plan.billing === billingCycle)
              .map((plan) => (
                <div key={plan.id} className="bg-gray-50 p-6 shadow-md rounded-lg">
                  <h3 className="font-semibold text-[#2D3142] text-lg mb-2">{plan.title}</h3>
                  <p className="text-[#17171780] text-sm mb-4">{plan.description}</p>
                  <div className="flex justify-center mb-6">
                    <span className="text-3xl font-bold text-gray-900">{plan.price}</span>
                    <span className="text-sm text-black ml-2">{plan.period}</span>
                  </div>
                  <ul className="text-[#04081580] list-disc list-inside mb-6">
                    {plan.features.split(", ").map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>
                  <button
                    onClick={() => handleUpgrade(plan.id)}
                    className="bg-[#2A9D8F] text-white px-6 py-3 rounded-full w-full hover:bg-teal-600 transition"
                  >
                    Subscribe Now
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpgradePage;
