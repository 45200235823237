import { Link } from 'react-router-dom'
import { FcFlashOn } from "react-icons/fc"
import { SlQuestion } from "react-icons/sl"

const Topbar = ({ title, setSidebarOpen, isSidebarOpen }) => {
    return (
        <header className="w-full  mb-8 px-6 md:px-12 py-6">
            {/* Hamburger Menu Icon */}
            <div className="flex justify-between items-center">
                <button
                    onClick={() => setSidebarOpen(true)}
                    className="sm:hidden text-gray-700 focus:outline-none"
                    aria-label={isSidebarOpen ? "Close menu" : "Open menu"}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d={
                                isSidebarOpen
                                    ? "M6 18L18 6M6 6l12 12"
                                    : "M4 6h16M4 12h16M4 18h16"
                            }
                        />
                    </svg>
                </button>

                <h1 className="text-2lg hidden sm:block md:text-xl font-medium text-black-100 font-bricolage">
                    {title}
                </h1>
                <div className="flex items-center space-x-4 sm:pr-10">
                    <Link
                        to="/upgrade"
                        className={`flex text-lg items-center py-2 px-4 rounded-full bg-[#2A9D8F] text-white hover:bg-opacity-80`}
                    >
                        <FcFlashOn size="22px" />
                        Upgrade
                    </Link>
                    <SlQuestion size={24} />
                </div>
            </div>
            <h1 className="text-2lg block sm:hidden mt-2 font-medium text-black-100 font-bricolage">
                {title}
            </h1>
        </header>
    )
}

export default Topbar

