import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { loginUser } from '../../utils/api';
import GoogleAuth from './GoogleAuth';
import Menu from "../Menu";
import { resendVerification } from '../../utils/api'; // Ensure this is imported

const Login = () => {
  const { setAuthState } = useContext(AuthContext);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Handle OAuth errors
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const oauthError = searchParams.get('error');

    if (oauthError) {
      switch (oauthError) {
        case 'oauth_failed':
          setError('Google authentication failed. Please try again.');
          break;
        default:
          setError('An error occurred during login.');
      }
    }
  }, [location, setError]);

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const resendVerificationEmail = async () => {
    try {
      setError(null); // Clear existing errors
      setLoading(true); // Show loading state
      await resendVerification({ email: formData.email }); // Pass the email from formData
      alert('Verification email has been resent. Please check your inbox.');
    } catch (err) {
      setError('Failed to resend the verification email. Please try again later.');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!formData.email || !formData.password) {
      setError('Both fields are required.');
      setLoading(false);
      return;
    }

    try {
      const { token, user } = await loginUser(formData);
      localStorage.setItem('token', token);
      setAuthState({ isAuthenticated: true, user, loading: false });
      navigate('/dashboard');
    } catch (err) {
      if (err.response && err.response.data) {
        const msg = err.response.data.msg;

        if (msg.includes('not registered')) {
          setError('The email address is not registered. Please sign up first.');
        } else if (msg.includes('Google Sign-In')) {
          setError('This account was created using Google Sign-In. Please log in using Google.');
        } else if (msg.includes('not verified')) {
          setError('Your email is not verified. Please check your inbox or resend the verification email.');
        } else if (msg.includes('Too many incorrect attempts')) {
          setError('Too many incorrect attempts. Please reset your password to unlock your account.');
        } else {
          setError(msg || 'Failed to log in. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center bg-[#F3FCFC] px-4 py-12 md:py-20 mt-8"
      style={{
        backgroundImage: "url('/assets/images/hero_bg_image.png')",
      }}
    >
      {/* Menu */}
      <Menu />

      {/* Heading */}
      <div className="max-w-2xl text-center mb-12">
        <h1
          className="font-bricolage font-semibold text-[32px] leading-[40px] md:text-[46.4px] md:leading-[56px]"
          style={{
            background: 'linear-gradient(180deg, #2D3142 0%, #2A9D8F 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Welcome Back
        </h1>
        <p className="font-dmSans font-bold text-[16px] leading-[24px] md:text-[19.3339px] md:leading-[150%] text-[#2D3142] mt-4">
          Log in to access your dashboard and start forecasting market trends.
        </p>
      </div>

      {/* Login Form */}
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-[32px] shadow-md p-6 md:p-8 w-full max-w-md"
      >
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700">
            {error}
            {error.includes('not verified') && (
              <button
                onClick={resendVerificationEmail}
                type="button"
                className="text-[#2A9D8F] font-semibold ml-2 underline hover:no-underline"
              >
                Resend Verification Email
              </button>
            )}
          </div>
        )}

        <div className="mb-4">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-[#2A9D8F] text-white font-dmSans font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600"
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>

      {/* Social Login */}
      <div className="mt-6 w-full max-w-md">
        <GoogleAuth />
      </div>

      {/* Signup Redirect */}
      <div className="mt-6 text-sm md:text-base font-dmSans text-[#2D3142]">
        Don't have an account?{' '}
        <Link
          to="/signup"
          className="text-[#2A9D8F] font-semibold hover:underline"
        >
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default Login;

