
const HomeIcon=()=>{
    return(
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 14C26 13.8232 25.9298 13.6537 25.8048 13.5286C25.6797 13.4036 25.5102 13.3334 25.3334 13.3334C25.1565 13.3334 24.987 13.4036 24.862 13.5286C24.7369 13.6537 24.6667 13.8232 24.6667 14H26ZM7.33336 14C7.33336 13.8232 7.26312 13.6537 7.1381 13.5286C7.01307 13.4036 6.84351 13.3334 6.66669 13.3334C6.48988 13.3334 6.32031 13.4036 6.19529 13.5286C6.07027 13.6537 6.00003 13.8232 6.00003 14H7.33336ZM27.528 17.1387C27.6532 17.2639 27.823 17.3342 28 17.3342C28.1771 17.3342 28.3468 17.2639 28.472 17.1387C28.5972 17.0135 28.6675 16.8437 28.6675 16.6667C28.6675 16.4897 28.5972 16.3199 28.472 16.1947L27.528 17.1387ZM16 4.6667L16.472 4.1947C16.4101 4.13261 16.3365 4.08336 16.2555 4.04975C16.1745 4.01614 16.0877 3.99884 16 3.99884C15.9123 3.99884 15.8255 4.01614 15.7445 4.04975C15.6635 4.08336 15.59 4.13261 15.528 4.1947L16 4.6667ZM3.52803 16.1947C3.40285 16.3199 3.33252 16.4897 3.33252 16.6667C3.33252 16.8437 3.40285 17.0135 3.52803 17.1387C3.65321 17.2639 3.82299 17.3342 4.00003 17.3342C4.17706 17.3342 4.34685 17.2639 4.47203 17.1387L3.52803 16.1947ZM9.33336 29.3334H22.6667V28H9.33336V29.3334ZM26 26V14H24.6667V26H26ZM7.33336 26V14H6.00003V26H7.33336ZM28.472 16.1947L16.472 4.1947L15.528 5.1387L27.528 17.1387L28.472 16.1947ZM15.528 4.1947L3.52803 16.1947L4.47203 17.1387L16.472 5.1387L15.528 4.1947ZM22.6667 29.3334C23.5507 29.3334 24.3986 28.9822 25.0237 28.3571C25.6488 27.7319 26 26.8841 26 26H24.6667C24.6667 26.5305 24.456 27.0392 24.0809 27.4142C23.7058 27.7893 23.1971 28 22.6667 28V29.3334ZM9.33336 28C8.80293 28 8.29422 27.7893 7.91915 27.4142C7.54408 27.0392 7.33336 26.5305 7.33336 26H6.00003C6.00003 26.8841 6.35122 27.7319 6.97634 28.3571C7.60146 28.9822 8.44931 29.3334 9.33336 29.3334V28Z" fill="#2D3142"/>
</svg>


    )
}
export default HomeIcon;

