import React from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const StockPredictionChart = ({ chartData, formatPrice }) => {
  return (
    <div className="h-72 bg-white rounded-lg border border-gray-200 shadow-sm p-4">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => new Date(value).toLocaleDateString()}
          />
          <YAxis 
            domain={['auto', 'auto']}
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => formatPrice(value)}
          />
          <Tooltip 
            formatter={(value) => formatPrice(value)}
            labelFormatter={(label) => new Date(label).toLocaleDateString()}
          />
          <Area
            type="monotone"
            dataKey="upperBound"
            stroke="none"
            fill="#38b6ff"
            fillOpacity={0.1}
          />
          <Area
            type="monotone"
            dataKey="lowerBound"
            stroke="none"
            fill="#38b6ff"
            fillOpacity={0.1}
          />
          <Line
            type="monotone"
            dataKey="price"
            stroke="#0066FF"
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockPredictionChart;
