import React from "react";
import { plans } from "../utils/helpers";
import Menu from "../components/Menu"

const PricingPage = () => {
  return (
    <div className="bg-white py-16 md:py-24 text-black-100 mt-12"
    
    style={{
        backgroundImage: "url('/assets/images/hero_bg_image.png')",
    }}>
           {/* Menu */}
           <Menu />
      <div className="container mx-auto px-4 lg:px-20">
        <h2 className="text-3xl font-bold font-bricolage text-center mb-2">
          Choose Your Plan
        </h2>
        <p className="font-dmSans text-md text-center mb-6 text-2lg text-[#2D3142]">
          Flexible pricing to meet your needs, whether you're just starting out or a seasoned professional.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div key={index} className="bg-white p-6 font-outfit shadow-md rounded-lg">
              <h3 className="font-semibold text-[#2D3142] text-[24px] sm:text-[26px] md:text-[29.97px] leading-[35.96px] mb-2">
                {plan.title}
              </h3>
              <p className="text-[#17171780] mb-4 text-[15.47px] leading-[18.56px]">
                {plan.description}
              </p>
              <div className="flex items-start mb-6">
                <div className="text-[28px] md:text-[37.7px] leading-[45.24px] font-bold text-gray-900">
                  {plan.price}
                </div>
                <div className="text-black text-md ml-2">
                  {plan.period.split(" ").map((word, idx) => (
                    <p key={idx}>{word}</p>
                  ))}
                </div>
              </div>
              <p className="mt-6 mb-8 text-[#04081580]">{plan.features}</p>
              <button className="bg-[#2A9D8F] text-white text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-full w-full hover:bg-teal-600 transition">
                Get Started
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
