import React from "react"
import { NavLink, useLocation } from "react-router-dom"

import { routes } from "../../utils/helpers"

const Sidebar = () => {
    let location = useLocation()

    return (
        <aside className=" border-r flex flex-col items-center py-6 bg-[#F5F5F5]">
            <div className="mb-8">
            <NavLink to="/dashboard">
                <img
                    src="/assets/icons/logo.svg"
                    alt="Trenviz Logo"
                    //className="w-8 md:w-10"
                />
                </NavLink>
            </div>
            <nav className="space-y-6 mt-12">
                {routes.map((item, index) => (
                    <NavLink
                        //end
                        to={item?.link}
                        key={index}
                        className={({ isActive }) => `pb-20 mb-10
                ${
                    isActive
                        ? `active   `
                        : `inactive w-full 
                    text-white-100  `
                }`}
                    >
                        <div className="mb-8">
                            {location.pathname === item.link ? (
                                <item.activeIcon />
                            ) : (
                                <item.icon />
                            )}
                        </div>
                    </NavLink>
                ))}
            </nav>
        </aside>
    )
}

export default Sidebar
