// context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getUserProfile } from '../utils/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Optionally, verify token validity
      getUserProfile(token)
        .then((user) => {
          setAuthState({ isAuthenticated: true, user, loading: false });
        })
        .catch((error) => {
          console.error('Error loading user profile:', error);
          localStorage.removeItem('token');
          setAuthState({ isAuthenticated: false, user: null, loading: false });
        });
    } else {
      // Set loading to false if no token is present
      setAuthState(prevState => ({ ...prevState, loading: false }));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};