// src/components/Modals/DashboardDisclaimer.jsx
import React, { useEffect } from 'react';

const DashboardDisclaimer = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        if (isOpen) {
            document.addEventListener('keydown', handleEsc);
        }
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 mx-4">
                {children}
                <button
                    onClick={onClose}
                    className="mt-4 w-full bg-[#2A9D8F] text-white py-2 px-4 rounded hover:bg-[#238f7d] transition-colors"
                >
                    Okay
                </button>
            </div>
        </div>
    );
};

export default DashboardDisclaimer;
