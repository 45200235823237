import React, { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import useStockPrediction from "../hooks/useStockPrediction"
import StockSearchForm from "../components/Dashboard/StockSearchForm"
import StockPredictionChart from "../components/StockPredictionChart"
import { ClipLoader } from "react-spinners" // Circular spinner
import Layout from "../components/Layout/layout"
import { FaChevronLeft } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import { PiWarningCircle } from "react-icons/pi"

const DashboardPage = () => {
    const { authState } = useContext(AuthContext)
    const navigate = useNavigate()
    const {
        ticker,
        setTicker,
        predictionData,
        loading,
        error,
        handleGeneratePrediction,
    } = useStockPrediction()

    const formatPrice = (price) =>
        price !== null && price !== undefined
            ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
              }).format(price)
            : "N/A"

    const calculateChange = (predictedPrice, lastPrice) => {
      if (!predictedPrice || !lastPrice) return null;
      return (((predictedPrice - lastPrice) / lastPrice) * 100).toFixed(2);
    };

    if (!authState.user) {
      return <p>Loading user data...</p>;
    }

    return (
        <Layout
            title={
                <span className="flex gap-2 items-center cursor-pointer">
                    <FaChevronLeft
                        color="#2D3142"
                        size={16}
                        onClick={() => navigate(-1)}
                    />{" "}
                    Result
                </span>
            }
        >
            <div className="px-4 pb-6">
                <div className="md:flex gap-4">
                    <div className="p-6 py-10 bg-white rounded-[24px] mx-auto lg:w-[70%] md:w-[65%]">
                        <h1 className="text-[20px] md:text-[22px] lg:text-[24px] leading-[18px] font-semibold mb-4 font-bricolage">
                            AMZN Search Result
                        </h1>
                        <StockSearchForm
                            ticker={ticker}
                            setTicker={setTicker}
                            handleGeneratePrediction={handleGeneratePrediction}
                            loading={false} // Set loading to false for the search box button
                            error={error}
                        />
                        {loading ? (
                            <div className="flex flex-col items-center justify-center h-64">
                                <ClipLoader color="#38b6ff" size={100} />{" "}
                                {/* Larger spinner */}
                                <p className="mt-4 text-sm text-gray-600">
                                    Running Simulations. Please wait, this may
                                    take a few moments...
                                </p>
                            </div>
                        ) : predictionData ? (
                            <div className="text-[#2D3142] font-dmSans">
                                <h3 className="font-bold font-bricolage text-[20px] md:text-[22px] lg:text-[25.8px]   leading-[25.96px] lg:leading-[30.96px] mb-2">AMZN <span className="font-normal ">Price Predication Chart</span></h3>
                                <p className="text-[14px] leading-[19.2px] mb-4">Next 5d trading period</p>
                                <StockPredictionChart
                                    chartData={predictionData?.chartData}
                                    formatPrice={formatPrice}
                                />
                            </div>
                        ) : (
                            <p className="text-center text-gray-500">
                                Enter a ticker to see predictions.
                            </p>
                        )}
                    </div>

                    <div className=" gap-2 mt-8 md:mt-0 md:w-[35%] lg:w-[30%] rounded-[24px] border bg-white p-6 py-10 text-[#2D3142]">
                        <div className="border-b border-dashed pb-4">
                            <h3 className="text-[18px] sm:text-[20px] leading-[24px]  mb-4 font-bricolage">
                                Predicted Summary
                            </h3>
                            <div className="space-y-6 text-[#2D3142] font-dmSans text-[14px] leading-[18px]">
                                <div className="flex justify-between">
                                    <span>Current Price</span>
                                    <span className="font-medium">
                                        {formatPrice(predictionData?.lastPrice)}
                                        
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span>Predicted Price (5d)</span>
                                    <span className="font-medium">
                                        {formatPrice(
                                            predictionData?.predictedPrice
                                        )}
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Change</span>
                                    <span
                                        className={`font-medium rounded-[24px] p-2 ${
                                            predictionData?.predictedPrice >
                                            predictionData?.lastPrice
                                                ? "text-[#28A745] bg-[#28A7451A] border border-[#28A745]"
                                                : "text-[#DC3545] bg-[#DC35451A] border border-[#DC3545]"
                                        }`}
                                    >
                                        
                                        {calculateChange(predictionData?.predictedPrice, predictionData?.lastPrice)}%
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-dashed py-4">
                            <h3 className= "text-[18px] sm:text-[20px] leading-[24px]  mb-4 font-bricolage">
                                Market Sentiment
                            </h3>
                            <div className="space-y-6 text-[#2D3142] font-dmSans text-[14px] leading-[18px]">
                                <div className="flex justify-between items-center">
                                    <span className="font-medium text-gray-600">
                                        Regime:
                                    </span>
                                    <span
                                        className={`px-2 py-1 rounded-full ${predictionData?.marketRegime} erke`}
                                    >
                                        {predictionData?.marketRegime ||
                                            "Unknown"}
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Volatility</span>
                                    <span
                                        className={`font-medium rounded-[24px] p-2 bg-[#F4A2611A]
                                                text-[#F4A261] border border-[#F4A261]
                                                    
                                            `}
                                    >
                                        Medium
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Trend</span>
                                    <span
                                        className={`font-medium rounded-[24px] p-2 
                                            
                                                bg-[#28A7451A] text-[#28A745] border border-[#28A745]"
                                                
                                        `}
                                    >
                                        Bullish
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <h3 className="text-[18px] sm:text-[20px] leading-[24px]  mb-4 font-bricolage">
                                Key Levels
                            </h3>
                            <div className="space-y-6 text-[#2D3142] font-dmSans text-[14px] leading-[18px]">
                                <div className="flex justify-between">
                                    <span>Resistance</span>
                                    <span className="font-semibold">
                                        $512.7
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span>Support</span>
                                    <span className="font-semibold">
                                        $512.7
                                    </span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span>Stop Loss</span>
                                    <span className="font-semibold text-[#DC3545]">
                                        $512.7
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Disclaimer */}
                <div className="bg-[#FF9F1C14]  rounded-lg p-2 w-fit text-[#FF9F1C] flex items-center mt-4">
                    <PiWarningCircle color="#FF9F1C" size="22px" />
                    <p className="text-[12.9px] font-medium ml-2 flex-1">
                        Disclaimer: Predictions are based on historical data and
                        market analysis. Past performance doesnot guarnatee
                        futuer results. Always conduct your own research before
                        making investments
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default DashboardPage
