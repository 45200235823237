import React from "react";
import Menu from "../components/Menu";

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen">
      {/* Menu */}
      <Menu />

      {/* Header Section */}
      <section className="py-12 bg-[#F3FCFC] text-center mt-20">
        <h1 className="font-bricolage font-semibold text-[35px] leading-[42px] text-[#2D3142]">
          Privacy Policy
        </h1>
        <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142] mt-4">
          Last Updated: Dec 9th, 2024
        </p>
      </section>

      {/* Content Section */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4 md:px-8 lg:px-36">
          <div className="text-left space-y-8">
            <h2 className="font-bricolage font-bold text-[28px] leading-[34px] text-[#2D3142]">
              Your Privacy Matters
            </h2>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              At Trenviz, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains what data we collect, how we use it, and your rights as a user.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              1. Information We Collect
            </h3>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>Email Address: For account creation, communication, and login.</li>
              <li>Phone Number: For account security, two-factor authentication, or account recovery.</li>
              <li>Google Authentication Data: If you sign in using Google, we collect your name and email address from Google for account authentication purposes.</li>
              <li>Usage Data: Information about how you interact with our Service, including page views, clicks, and feature usage.</li>
              <li>Cookies: We use cookies to enhance your experience, track website performance, and improve our Service.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              2. How We Use Your Information
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We use your information for the following purposes:
            </p>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>To create and manage your account.</li>
              <li>To communicate updates, promotions, or Service-related information.</li>
              <li>To analyze and improve the performance and functionality of our platform.</li>
              <li>To ensure compliance with legal and regulatory requirements.</li>
              <li>To detect and prevent fraud, security breaches, or misuse of our Service.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              3. Data Sharing and Disclosure
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We do not sell, trade, or share your personal information with third parties, except in the following cases:
            </p>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>With third-party payment processors (e.g., Stripe, PayPal) to process transactions securely.</li>
              <li>With legal authorities, if required to comply with legal obligations or respond to lawful requests.</li>
              <li>In the event of a business transfer, merger, or acquisition, where your data may be part of the transferred assets.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              4. Data Retention
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We retain your personal information only as long as necessary to provide the Service and fulfill the purposes outlined in this Privacy Policy. Once data is no longer required, we securely delete or anonymize it.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              5. Your Rights
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              As a user, you have the following rights:
            </p>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>Access: Request access to the personal data we hold about you.</li>
              <li>Correction: Request corrections to inaccurate or incomplete information.</li>
              <li>Deletion: Request deletion of your personal data, subject to legal requirements.</li>
              <li>Data Portability: Receive a copy of your personal data in a structured format.</li>
              <li>Withdraw Consent: Withdraw consent for data collection or usage.</li>
            </ul>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              To exercise your rights, please contact us at <a href="mailto:admin@trenviz.com" className="text-[#2A9D8F] hover:underline">admin@trenviz.com</a>.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              6. Security Measures
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We implement industry-standard security measures to protect your data against unauthorized access, loss, or misuse. However, no online platform can guarantee absolute security. We encourage you to safeguard your account credentials.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              7. Cookies and Tracking
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We use cookies to enhance your experience and analyze website performance. You can disable cookies in your browser settings, but some features of the Service may not function properly without them.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              8. Changes to the Privacy Policy
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We may update this Privacy Policy from time to time. Changes will be communicated via the website or email. Continued use of the Service constitutes acceptance of the updated Privacy Policy.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              9. Contact Us
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:admin@trenviz.com" className="text-[#2A9D8F] hover:underline">admin@trenviz.com</a>.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
