// components/GoogleAuth.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const GoogleAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoogleLogin = () => {
    // Redirect the user to the server's Google OAuth endpoint
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const error = searchParams.get('error');
  
    if (token) {
      localStorage.setItem('token', token);
      localStorage.removeItem('oauth_error');
      navigate('/dashboard');
    }
  
    if (error) {
      switch (error) {
        case 'oauth_failed':
          localStorage.setItem('oauth_error', 'Google authentication failed. Please try again.');
          break;
        case 'email_unverified':
          localStorage.setItem('oauth_error', 'Your email is linked to Google Sign-In but is unverified. Please verify your email.');
          break;
        default:
          localStorage.setItem('oauth_error', 'An unexpected error occurred. Please try again.');
      }
      navigate('/login');
    }
    
  }, [location, navigate]);

  return (
    <button
      onClick={handleGoogleLogin}
      className="flex items-center justify-center w-full bg-white border border-gray-300 rounded-[32px] px-4 py-3 shadow-sm hover:shadow-md transition-colors duration-300"
    >
      {/* Google Logo as SVG */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        className="w-5 h-5 mr-3"
      >
        <path
          fill="#FFC107"
          d="M43.6 20.4H42V20H24v8h11.3C33.4 32.6 29.2 36 24 36c-6.6 0-12-5.4-12-12s5.4-12 12-12c3 0 5.7 1.1 7.8 3l5.7-5.7C33.4 6.7 28.9 4 24 4 12.9 4 4 12.9 4 24s8.9 20 20 20c10.2 0 18.4-7.4 19.8-17h-20z"
        />
        <path
          fill="#FF3D00"
          d="M6.3 14.6l6.6 4.8c1.8-3.5 4.9-6.1 8.6-7.3l-4.3-7C12.2 6.9 8.4 10.3 6.3 14.6z"
        />
        <path
          fill="#4CAF50"
          d="M24 44c5.5 0 10.3-2 14-5.3l-6.4-5.7C29.4 35.2 26.8 36 24 36c-4.5 0-8.5-2.5-10.5-6.2l-6.8 5.1C10.1 40.5 16.7 44 24 44z"
        />
        <path
          fill="#1976D2"
          d="M43.6 20.4H42V20H24v8h11.3c-.9 2.6-2.7 4.9-5.3 6.4l6.4 5.7c.1 0 .2-.1.3-.1 3.7-2.9 6.4-7.2 7.6-12.3H24v-8h19.6z"
        />
      </svg>
      <span className="text-gray-700 text-sm md:text-base font-medium">
        Continue with Google
      </span>
    </button>
  );
};

export default GoogleAuth;
