import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import DashboardPage from "./pages/DashboardPage";
import DashboardWelcomePage from "./pages/dashboardWelcomePage";
import HistoryPage from "./pages/historyPage";
import SettingsPage from "./pages/settingsPage";
import Subscribe from "./components/Subscription/Subscribe";
import LandingPage from "./pages/LandingPage"; // Import the LandingPage
import OAuthSuccess from "./components/Auth/OAuthSuccess";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import UpgradePage from "./pages/UpgradePage";
import PricingPage from "./pages/PricingPage";
import FAQPage from "./pages/FAQPage";
import PaymentSuccess from "./components/Subscription/PaymentSuccess";
import ResultDetailsPage from "./pages/ResultDetailsPage"; // Add this line

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<LandingPage />} />{" "}
                    {/* Landing Page as default */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPolicyPage />} />
                    <Route path="/oauth-success" element={<OAuthSuccess />} />
                    <Route path="/upgrade" element={<UpgradePage />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path="/result/:ticker" element={<ResultDetailsPage />} />

                    {/* Protected Routes */}
                    <Route
                        path="/dashboard/result"
                        element={
                            <ProtectedRoute>
                                <DashboardPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/subscribe"
                        element={
                            <ProtectedRoute>
                                <Subscribe />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <DashboardWelcomePage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/history"
                        element={
                            <ProtectedRoute>
                                <HistoryPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute>
                                <SettingsPage />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </Router>
        </AuthProvider>
    );
}
export default App;
