import React from "react"

const ChangePasswordModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null

    return (
        <div className="fixed inset-0 bg-[#000000] bg-opacity-50 flex items-center justify-center z-50">
            {/* Modal Content */}
            <div className="bg-white w-11/12 sm:w-[550px] rounded-[32px] shadow-lg p-6">
                {/* Modal Header */}
                <div className="flex justify-between text-center items-center mb-6">
                    <h2 className="text-[20px] leading-[24px] font-bold font-bricolage flex-1 text-center">
                        Change Password
                    </h2>
                    <button onClick={onClose} className="">
                        <img src="/assets/icons/close_icon.svg" alt="" />
                    </button>
                </div>

                {/* Form Fields */}
                <form className="font-dmSans">
                    <div className="space-y-4">
                        {/* Old Password */}
                        <div>
                            <input
                                type="password"
                                placeholder="Enter old password"
                                className="w-full border rounded-3xl px-4 py-2 text-gray-700 focus:ring-2 focus:ring-teal-500 focus:outline-none"
                            />
                        </div>
                        {/* New Password */}
                        <div>
                            <input
                                type="password"
                                placeholder="Enter new password"
                                className="w-full border rounded-3xl px-4 py-2 text-gray-700 focus:ring-2 focus:ring-teal-500 focus:outline-none"
                            />
                        </div>
                        {/* Confirm New Password */}
                        <div>
                            <input
                                type="password"
                                placeholder="Confirm new password"
                                className="w-full border rounded-3xl px-4 py-2 text-gray-700 focus:ring-2 focus:ring-teal-500 focus:outline-none"
                            />
                        </div>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="mt-6 w-full bg-[#2A9D8F] hover:bg-teal-600 text-[#FFC107] font-semibold py-2 px-4 rounded-3xl focus:outline-none focus:ring-2 focus:ring-teal-400"
                    >
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ChangePasswordModal
