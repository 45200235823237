import { useState } from "react";
import { fetchStockPrediction } from "../services/api";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { saveSearch } from "../utils/search"; // Import saveSearch

const useStockPrediction = () => {
  const [ticker, setTicker] = useState("QQQ");
  const [predictionData, setPredictionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const handleGeneratePrediction = async () => {
    setLoading(true);
    setError("");
    try {
        const today = new Date();
        const endDate = today.toISOString().split("T")[0];
        const startDateObj = new Date(today);
        startDateObj.setFullYear(today.getFullYear() - 2); // 2 years of historical data
        const startDate = startDateObj.toISOString().split("T")[0];

        const response = await fetchStockPrediction({
            ticker,
            startDate,
            endDate,
            timeframe: "10d",
        });

        const { dates, prices, technical_levels, market_regime, scenarios } = response;

        if (!dates || !prices || !scenarios) {
            throw new Error("Incomplete data received from the backend.");
        }

        // Process prediction data
        const historical = prices.historical || [];
        const predictions = prices.predictions.average || [];
        const confidence_intervals = {
            bear_case: scenarios["95%"][0],
            bull_case: scenarios["95%"][1],
        };

        const allDates = [...dates.historical, ...dates.predicted];
        const historicalLength = historical.length;

        const chartData = allDates.map((date, index) => ({
            date,
            price: index < historicalLength ? historical[index] : predictions[index - historicalLength],
            lowerBound:
                index < historicalLength
                    ? null
                    : confidence_intervals.bear_case[index - historicalLength] || null,
            upperBound:
                index < historicalLength
                    ? null
                    : confidence_intervals.bull_case[index - historicalLength] || null,
            isProjection: index >= historicalLength,
        }));

        const lastPrice = historical[historicalLength - 1];
        const predictedPrice = predictions[predictions.length - 1];
        const resultLink = `/results/${ticker}`; // Construct result link

        // Save processed prediction data
        setPredictionData({
            chartData,
            lastPrice,
            predictedPrice,
            marketRegime: market_regime,
            resistance: technical_levels.resistance[0], // Mapping the first resistance level
            support: technical_levels.support[0], // Mapping the first support level
            stopLoss: technical_levels.stopLoss || 0, // Assuming stopLoss is part of technical_levels
        });

        // Save the search to the database
        await saveSearch(
          ticker,
          lastPrice,
          resultLink,
          chartData,
          predictedPrice,
          technical_levels.support[0],
          technical_levels.resistance[0],
          technical_levels.stopLoss,
          market_regime
      );
      

    } catch (err) {
        if (err.response?.status === 403) {
            alert(err.response.data.message);
            navigate("/upgrade");
        } else {
            console.error("Error fetching prediction:", err);
            setError("Failed to fetch prediction. Please check the ticker symbol or try again later.");
        }
    } finally {
        setLoading(false);
    }
};

  return { ticker, setTicker, predictionData, loading, error, handleGeneratePrediction };
};

export default useStockPrediction;
