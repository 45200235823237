const ActiveHistoryIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                opacity="0.16" 
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" 
                fill="#2A9D8F"
            />
            <path
                d="M7.51467 24.4853C9.47793 26.4485 12.0612 27.6703 14.8244 27.9423C17.5875 28.2143 20.3595 27.5197 22.6679 25.977C24.9763 24.4342 26.6783 22.1387 27.4838 19.4816C28.2894 16.8245 28.1486 13.9703 27.0856 11.4054C26.0225 8.84052 24.1029 6.7236 21.6539 5.41544C19.2049 4.10727 16.3781 3.68881 13.6551 4.23136C10.9321 4.77391 8.48155 6.2439 6.72098 8.39083C4.96041 10.5378 3.99879 13.2288 4 16.0052V18.6666" 
                stroke="#2A9D8F" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M1.33337 16.0001L4.00004 18.6667L6.66671 16.0001M14.6667 10.6667V17.3334H21.3334" 
                stroke="#2A9D8F" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
            />
        </svg>
    );
}

export default ActiveHistoryIcon;
