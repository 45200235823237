import axios from 'axios';

export const saveSearch = async (
    ticker,
    currentPrice,
    resultLink,
    chartData,
    predictedPrice,
    support,
    resistance,
    stopLoss,
    marketRegime
) => {
    const token = localStorage.getItem("token"); // JWT for auth
    try {
        await axios.post(
            `${process.env.REACT_APP_API_URL}/api/search/add`,
            {
                ticker,
                currentPrice,
                resultLink,
                chartData,
                predictedPrice,
                support,
                resistance,
                stopLoss,
                marketRegime,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    } catch (err) {
        console.error("Failed to save search:", err);
    }
};
