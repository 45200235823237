const HistoryIcon = () => {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9493 27.3333C13.2417 27.3333 10.8813 26.4489 8.86797 24.68C6.85552 22.912 5.69108 20.6853 5.37463 18H6.72263C7.0853 20.2933 8.13019 22.2 9.8573 23.72C11.5844 25.24 13.6151 26 15.9493 26C18.5493 26 20.7546 25.0947 22.5653 23.284C24.376 21.4733 25.2817 19.2676 25.2826 16.6667C25.2835 14.0658 24.3777 11.86 22.5653 10.0493C20.7529 8.23867 18.5475 7.33333 15.9493 7.33333C14.5689 7.33333 13.272 7.62489 12.0586 8.208C10.8453 8.79022 9.77419 9.59244 8.8453 10.6147H12.1533V11.948H6.61597V6.412H7.9493V9.596C8.98041 8.46444 10.1884 7.58267 11.5733 6.95067C12.9582 6.31867 14.4169 6.00178 15.9493 6C17.4275 6 18.8133 6.27822 20.1066 6.83467C21.4 7.39111 22.5297 8.15289 23.496 9.12C24.4622 10.0871 25.224 11.2169 25.7813 12.5093C26.3386 13.8018 26.6169 15.1876 26.616 16.6667C26.6151 18.1458 26.3369 19.5316 25.7813 20.824C25.2257 22.1164 24.464 23.2462 23.496 24.2133C22.528 25.1804 21.3982 25.9422 20.1066 26.4987C18.8151 27.0551 17.4293 27.3333 15.9493 27.3333ZM20.2213 21.8053L15.3586 16.944V10H16.692V16.3893L21.164 20.8613L20.2213 21.8053Z"
                fill="#2D3142"
                fillOpacity="0.7" // Updated from fill-opacity to fillOpacity
            />
        </svg>
    )
}

export default HistoryIcon;
