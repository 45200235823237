// components/Auth/OAuthSuccess.js
import { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const OAuthSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      setAuthState({ isAuthenticated: true, user: null, loading: false });
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/login');
    }
  }, [location, navigate, setAuthState]);

  return null;
};

export default OAuthSuccess;
