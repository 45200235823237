import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../components/Layout/layout";
import { ClipLoader } from "react-spinners";

const ResultDetailsPage = () => {
    const { ticker } = useParams(); // Extract ticker from the route
    const [predictionData, setPredictionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPredictionData = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/search/detail?ticker=${ticker}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setPredictionData(data);
                } else {
                    const errorResponse = await response.json();
                    setError(errorResponse.msg || "Failed to fetch prediction details.");
                }
            } catch (error) {
                console.error("Error fetching prediction details:", error.message);
                setError("An error occurred while fetching the data.");
            } finally {
                setLoading(false);
            }
        };

        fetchPredictionData();
    }, [ticker]);

    const formatPrice = (price) =>
        price !== null && price !== undefined
            ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
              }).format(price)
            : "N/A";

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center h-64">
                <ClipLoader color="#38b6ff" size={100} />
                <p className="mt-4 text-sm text-gray-600">Loading data...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-64">
                <p className="text-red-500">{error}</p>
                <button
                    onClick={() => navigate(-1)}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Go Back
                </button>
            </div>
        );
    }

    return (
        <Layout title={`Details for ${ticker}`}>
            <div className="max-w-6xl mx-auto bg-white rounded-lg p-8 py-10">
                <h2 className="text-2xl font-semibold mb-6">Details for {ticker}</h2>
                <p className="text-sm text-gray-500 mb-6">
                    As of: {new Date(predictionData.searchDate).toLocaleDateString()}{" "}
                    {new Date(predictionData.searchDate).toLocaleTimeString()}
                </p>
                <div className="mt-6">
                    <h3 className="text-lg font-semibold mb-4">Prediction Summary</h3>
                    <p>Current Price: {formatPrice(predictionData.currentPrice)}</p>
                    <p>Predicted Price (5d): {formatPrice(predictionData.predictedPrice)}</p>
                    <p>
                        Change:{" "}
                        {(
                            ((predictionData.predictedPrice - predictionData.currentPrice) /
                                predictionData.currentPrice) *
                            100
                        ).toFixed(2)}%
                    </p>

                    <h3 className="text-lg font-semibold mb-4 mt-6">Market Sentiment</h3>
                    <p>Regime: {predictionData.marketRegime || "N/A"}</p>

                    <h3 className="text-lg font-semibold mb-4 mt-6">Key Levels</h3>
                    <p>Resistance: {formatPrice(predictionData.resistance)}</p>
                    <p>Support: {formatPrice(predictionData.support)}</p>
                    <p>Stop Loss: {formatPrice(predictionData.stopLoss)}</p>
                </div>
            </div>
        </Layout>
    );
};

export default ResultDetailsPage;
