import HistoryIcon from "../components/Layout/assets/historyIcon";
import HomeIcon from "../components/Layout/assets/homeIcon";
import SettingsIcon from "../components/Layout/assets/settingsIcon";
import ActiveHomeIcon from "../components/Layout/assets/activeHomeIcon";
import ActiveHistoryIcon from "../components/Layout/assets/activeHistoryIcon";
import ActiveSettingsIcon from "../components/Layout/assets/activeSettingsIcon";

export const faqs = [
  { question: "What is Trenviz?", answer: "Trenviz is a platform for tracking market trends effortlessly." },
  { question: "How do I search for a ticker?", answer: "You can use the search bar to look for specific tickers." },
  { 
    question: "How does the free trial work?", 
    answer: "All new users can try any plan for free for 7 days without needing to provide a card. You’ll get full access to the features during the trial period." 
  },
  { question: "Can I upgrade my plan later?", answer: "Yes, you can upgrade at any time from your account settings." },
  { question: "Can I cancel my subscription anytime?", answer: "Yes, you can cancel your subscription at any time." },
  { question: "Is my data secure?", answer: "Absolutely! We prioritize the security of your data." },
];

export const plans = [
  {
    id: "starter_monthly", // Should match backend
    title: "Starter Plan",
    description: "Ideal for individual financial analysts.",
    price: "$9.99",
    period: "Per Month",
    features: "Access to basic simulation tools and predictions.",
    billing: "monthly",
  },
  {
    id: "starter_yearly", // Should match backend
    title: "Starter Plan",
    description: "Save 20% with an annual subscription.",
    price: "$99.99",
    period: "Per Year",
    features: "Access to basic simulation tools and predictions.",
    billing: "yearly",
  },
  // {
  //   id: "pro_monthly", // Should match backend
  //   title: "Pro Plan",
  //   description: "For advanced users who need detailed predictions.",
  //   price: "$19.99",
  //   period: "Per Month",
  //   features: "Includes advanced simulation tools and insights.",
  //   billing: "monthly",
  // },
  // {
  //   id: "pro_yearly", // Should match backend
  //   title: "Pro Plan",
  //   description: "Save 20% with an annual subscription.",
  //   price: "$199.99",
  //   period: "Per Year",
  //   features: "Includes advanced simulation tools and insights.",
  //   billing: "yearly",
  // },
];



export const routes = [
  { link: "/dashboard", icon: HomeIcon, activeIcon: ActiveHomeIcon },
  { link: "/history", icon: HistoryIcon, activeIcon: ActiveHistoryIcon },
  { link: "/settings", icon: SettingsIcon, activeIcon: ActiveSettingsIcon },
];
