import React, { useState } from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Common styles
  const linkBaseStyles =
    "text-[#2D3142] font-dmSans text-[15.4671px] leading-[20px] text-center hover:text-teal-600";
  const buttonBaseStyles =
    "px-6 py-2 font-dmSans text-[15.4671px] leading-[20px] text-center rounded-[24px]";

  return (
    <header className="w-full bg-white fixed top-0 z-50">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between space-x-4">
        {/* Logo Section */}
        <div>
        <Link to="/">
          <img
            src="/assets/images/trenviz_logo.png"
            alt="Trenviz Logo"
            className="h-8"
          />
          </Link>
        </div>

        {/* Hamburger Menu Icon */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-gray-700 focus:outline-none"
          aria-label={isOpen ? "Close menu" : "Open menu"}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
            />
          </svg>
        </button>

        {/* Desktop Links */}
        <nav className="hidden md:flex items-center space-x-6">
          <Link to="/pricing" className={linkBaseStyles}>
            Pricing
          </Link>
          <Link to="/faq" className={linkBaseStyles}>
            FAQs
          </Link>
          <Link
            to="/login"
            className={`${buttonBaseStyles} bg-[#F8F9FA] text-[#2D3142] border border-gray-300 hover:border-teal-600`}
          >
            Log In
          </Link>
          <Link
            to="/signup"
            className={`${buttonBaseStyles} bg-[#2A9D8F] text-white hover:bg-teal-600`}
          >
            Get Started
          </Link>
        </nav>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-white md:hidden z-50 transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-4 text-gray-700 focus:outline-none"
          aria-label="Close menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <nav className="flex flex-col items-center space-y-6 py-8 px-4">
          <Link
            to="/pricing"
            className={`${linkBaseStyles} w-full py-3`}
            onClick={toggleMenu}
          >
            Pricing
          </Link>
          <Link
            to="/faq"
            className={`${linkBaseStyles} w-full py-3`}
            onClick={toggleMenu}
          >
            FAQs
          </Link>
          <Link
            to="/login"
            className={`${buttonBaseStyles} w-full bg-[#F8F9FA] text-[#2D3142] border border-gray-300 hover:border-teal-600`}
            onClick={toggleMenu}
          >
            Log In
          </Link>
          <Link
            to="/signup"
            className={`${buttonBaseStyles} w-full bg-[#2A9D8F] text-white hover:bg-teal-600`}
            onClick={toggleMenu}
          >
            Get Started
          </Link>
        </nav>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
          aria-hidden="true"
        ></div>
      )}
    </header>
  );
};

export default Menu;
