import React from "react";
import Menu from "../components/Menu";

const TermsPage = () => {
  return (
    <div className="min-h-screen">
      {/* Menu */}
      <Menu />

      {/* Header Section */}
      <section className="py-12 bg-[#F3FCFC] text-center mt-20">
        <h1 className="font-bricolage font-semibold text-[35px] leading-[42px] text-[#2D3142]">
          Terms of Service
        </h1>
        <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142] mt-4">
          Last Updated: Dec 9th, 2024
        </p>
      </section>

      {/* Content Section */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4 md:px-8 lg:px-36">
          <div className="text-left space-y-8">
            <h2 className="font-bricolage font-bold text-[28px] leading-[34px] text-[#2D3142]">
              Welcome to Trenviz
            </h2>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              These Terms of Service (“Terms”) govern your use of our web application (“Service”). By accessing or using Trenviz, you agree to these Terms. If you do not agree, please do not use our Service.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              1. Introduction
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              Trenviz provides a platform that predicts stock trends using historical data and advanced algorithms. Please note that our predictions are speculative and should not be relied upon as financial or investment advice. By using our Service, you acknowledge and agree to these limitations.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              2. User Responsibilities
            </h3>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>Use the Service only for lawful purposes.</li>
              <li>Provide accurate, truthful, and up-to-date information during registration.</li>
              <li>Not misuse or attempt to disrupt the functionality of the Service.</li>
              <li>Acknowledge the speculative nature of predictions and accept full responsibility for financial decisions made based on our insights.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              3. Limitations of Liability
            </h3>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>The insights provided are speculative and may not reflect actual market movements.</li>
              <li>Trenviz is not responsible for any financial losses, damages, or liabilities arising from the use of the Service.</li>
              <li>You are solely responsible for your investment decisions.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              4. Prohibited Activities
            </h3>
            <ul className="list-disc pl-8 text-[#2D3142] font-dmSans font-medium text-[16px] leading-[26px]">
              <li>Reverse-engineering, copying, or attempting to replicate Trenviz’s algorithms.</li>
              <li>Using the Service to distribute spam, malicious content, or unauthorized advertisements.</li>
              <li>Accessing or attempting to access the Service via automated tools or bots without prior authorization.</li>
              <li>Engaging in activities that disrupt or harm the Service or other users.</li>
            </ul>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              5. Payment and No-Refund Policy
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              Payments for subscriptions and additional features are processed through third-party providers such as Stripe and PayPal. All payments made to Trenviz are final and non-refundable. By subscribing to our Service, you acknowledge and agree to this no-refund policy. If you experience issues with the Service, please contact us at <a href="mailto:admin@trenviz.com" className="text-[#2A9D8F] hover:underline">admin@trenviz.com</a>.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              6. Account Termination
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We reserve the right to suspend or terminate your account without prior notice if you violate these Terms or misuse the Service. In cases of termination, any unused subscriptions or features will not be refunded.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              7. Governing Law
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              These Terms are governed by the laws of the Province of Ontario and the federal laws of Canada. Any disputes arising from these Terms will be resolved exclusively in the courts of Ontario.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              8. Changes to the Terms
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              We may update these Terms from time to time. Changes will be communicated through the website, and continued use of the Service constitutes your acceptance of the revised Terms.
            </p>

            <h3 className="font-bricolage font-bold text-[22px] leading-[30px] text-[#2D3142]">
              9. Contact Us
            </h3>
            <p className="font-dmSans font-medium text-[16px] leading-[26px] text-[#2D3142]">
              If you have any questions about these Terms, please contact us at <a href="mailto:admin@trenviz.com" className="text-[#2A9D8F] hover:underline">admin@trenviz.com</a>.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsPage;
