import Layout from "../components/Layout/layout";
import { useState, useEffect } from "react";
import { TbLogout2 } from "react-icons/tb";
import { RiAccountCircleLine } from "react-icons/ri";
import ChangePasswordModal from "../components/Modals/changePassword";
import { useNavigate } from "react-router-dom";
import DeleteAccountModal from "../components/Modals/DeleteAccountModal";

const SettingsPage = () => {
    const [settings, setSettings] = useState("accountPreference");
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [userData, setUserData] = useState(null); // Holds user data
    const [phoneEditable, setPhoneEditable] = useState(false); // Toggles edit mode for phone number
    const [updatedPhone, setUpdatedPhone] = useState(""); // Holds updated phone number
    const navigate = useNavigate();

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token"); // JWT token
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/profile`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserData(data); // Set fetched user data
                    setUpdatedPhone(data.phone || ""); // Initialize phone number
                } else {
                    console.error("Failed to fetch user profile");
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        fetchUserData();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
    };

    const handleDeleteAccount = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                alert("Your account has been deleted.");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/login");
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.msg}`);
            }
        } catch (error) {
            console.error("Error deleting account:", error);
            alert("Something went wrong. Please try again later.");
        }
    };

    const handlePhoneUpdate = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/update`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ phone: updatedPhone }),
            });

            if (response.ok) {
                alert("Phone number updated successfully!");
                setPhoneEditable(false); // Exit edit mode
                const updatedData = await response.json();
                setUserData((prevData) => ({ ...prevData, phone: updatedData.phone }));
            } else {
                alert("Failed to update phone number. Please try again.");
            }
        } catch (error) {
            console.error("Error updating phone number:", error);
        }
    };

    return (
        <Layout title="Settings">
            {/* Modals */}
            <ChangePasswordModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
            />
            <DeleteAccountModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={handleDeleteAccount}
            />
    
            <div
                className="flex flex-col md:flex-row bg-no-repeat w-full font-dmSans pb-8 pl-2"
                style={{ backgroundImage: "url('/assets/images/chart2.png')" }}
            >
                {/* Sidebar */}
                <div className="md:w-[20%] sm:w-full sm:mb-4 bg-[#F5F5F5] h-fit p-4">
                    <div
                        className={`flex cursor-pointer mb-10 items-center text-md sm:text-2md ${
                            settings === "accountPreference" ? "text-black-100" : "text-[#00000099]"
                        }`}
                        onClick={() => setSettings("accountPreference")}
                    >
                        <RiAccountCircleLine size="24px" />
                        <p className="ml-2 font-medium">Account Preferences</p>
                    </div>
    
                    <div
                        className="flex cursor-pointer mb-10 items-center sm:text-2md text-md text-[#00000099]"
                        onClick={handleLogout}
                    >
                        <TbLogout2 size="24px" />
                        <p className="ml-2">Log Out</p>
                    </div>
    
                    <div
                        className="flex cursor-pointer mb-10 sm:text-2md text-md text-[#00000099]"
                        onClick={() => setDeleteModalOpen(true)}
                    >
                        <img src="/assets/icons/trash.svg" alt="Delete Icon" />
                        <p className="ml-2">Delete Account</p>
                    </div>
                </div>
    
                {/* Main Card */}
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="w-[90%] md:w-[70%] lg:w-[60%] bg-white rounded-lg shadow-lg p-6 sm:p-8 space-y-8">
                        <section>
                            <h2 className="text-[20px] sm:text-[24px] font-medium text-gray-800 mb-6 font-bricolage">
                                Personal Information
                            </h2>
                            {userData ? (
                                <div className="grid md:grid-cols-2 gap-6 text-[14px] leading-[18px]">
                                    <div>
                                        <p className="font-semibold pb-3">Name</p>
                                        <p>{userData.name || "Name not set"}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold pb-3">Email</p>
                                        <p>{userData.email}</p>
                                    </div>
                                    <div>
                                        <p className="font-semibold pb-3">Phone Number</p>
                                        {phoneEditable ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    value={updatedPhone}
                                                    onChange={(e) => setUpdatedPhone(e.target.value)}
                                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                                />
                                                <button
                                                    onClick={handlePhoneUpdate}
                                                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded-lg"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={() => setPhoneEditable(false)}
                                                    className="mt-2 ml-2 px-4 py-2 bg-gray-300 rounded-lg"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <p>{userData.phone || "Phone number not set"}</p>
                                                <button
                                                    onClick={() => setPhoneEditable(true)}
                                                    className="text-[#67B7AE] underline text-sm"
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <p className="font-semibold pb-3">Plan</p>
                                        <p>{userData.subscriptionStatus}</p>
                                    </div>
                                </div>
                            ) : (
                                <p>Loading user information...</p>
                            )}
                        </section>
    
                        <section>
                            <h2 className="text-[20px] sm:text-[24px] font-medium font-bricolage mb-4">
                                Password Information
                            </h2>
                            <div className="flex flex-col">
                                <p className="font-semibold pb-3 text-[14px] leading-[18px]">
                                    Password
                                </p>
                                <p className="text-gray-500">*******************</p>
                                <button
                                    className="text-[#67B7AE] underline text-left text-sm"
                                    onClick={() => setModalOpen(true)}
                                >
                                    Reset Password
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
    
};

export default SettingsPage;
