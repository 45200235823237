import axios from 'axios';

export const BACKEND_URL = process.env.REACT_APP_API_URL;

export const fetchStockPrediction = async (params) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BACKEND_URL}/api/advanced-market-prediction`, {
      params,
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    return response.data;
  } catch (error) {
    //console.error('Error fetching stock prediction:', error); //commented out for now unless i want to debug
    throw error;
  }
};
