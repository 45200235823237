import Sidebar from "./sidebar"
import Topbar from "./topbar"
import React, { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { routes } from "../../utils/helpers"

const Layout = ({ children, title }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    let location = useLocation()
    return (
        <div className="relative   md:pl-24  min-h-screen bg-[#F5F5F5] w-full">
            {/* Sidebar */}
            <div className="fixed hidden md:block left-0  sm:w-20 md:w-24">
                <Sidebar isSidebarOpen={isSidebarOpen} />
            </div>
            {/* Mobile Menu */}
            <div
                className={`fixed inset-0 bg-white md:hidden z-50 transform transition-transform duration-300 ease-in-out w-3/4 ${
                    isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                }`}
            >
                <button
                    onClick={() => setSidebarOpen(!isSidebarOpen)}
                    className="absolute top-4 right-4  focus:outline-none"
                    aria-label="Close menu"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                <nav className="flex flex-col items-center space-y-6 py-8 px-4">
                    <img
                        src="/assets/icons/logo.svg"
                        alt="Trenviz Logo"
                        //className="w-8 md:w-10"
                    />
                    {routes.map((item, index) => (
                        <NavLink
                            //end
                            to={item?.link}
                            key={index}
                        >
                            <div className="mb-4 items-center">
                                {location.pathname === item.link ? (
                                    <item.activeIcon />
                                ) : (
                                    <item.icon />
                                )}
                            </div>
                        </NavLink>
                    ))}
                </nav>
            </div>

            {/* Overlay */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={() => setSidebarOpen(!isSidebarOpen)}
                    aria-hidden="true"
                ></div>
            )}

            {/* Main Content */}
            <main className="   w-full items-center ">
                {/* Header */}
                <Topbar
                    title={title}
                    isSidebarOpen={isSidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                {children}
            </main>
        </div>
    )
}

export default Layout
