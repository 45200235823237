import React, { useState } from "react";
import { faqs } from "../utils/helpers";
import Menu from "../components/Menu"

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="bg-[#F7FCFC] py-16 md:py-24 text-black-100 mt-12"
    
    style={{
        backgroundImage: "url('/assets/images/hero_bg_image.png')",
    }}>
           {/* Menu */}
           <Menu />
      <div className="container mx-auto px-4 lg:px-20">
        <h2 className="text-3xl font-bold font-bricolage text-center mb-4">
          Frequently Asked Questions
        </h2>
        <p className="font-dmSans text-md text-center mb-8 text-[#2D3142]">
          Have questions? We’ve got answers.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-dmSans text-[#2C3E50] text-[20px] leading-[24px]">
                  {faq.question}
                </h3>
                <button
                  className={`text-2xl font-bold transform transition-transform ${
                    openIndex === index ? "rotate-45" : "rotate-0"
                  }`}
                >
                  +
                </button>
              </div>
              {openIndex === index && (
                <p className="mt-4 text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
