import Layout from "../components/Layout/layout";
import { useState, useEffect } from "react";

const HistoryPage = () => {
    const [searchHistory, setSearchHistory] = useState([]);

    useEffect(() => {
        const fetchSearchHistory = async () => {
            try {
                const token = localStorage.getItem("token"); // JWT token
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search/history`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setSearchHistory(data); // Set search history data
                } else {
                    console.error("Failed to fetch search history");
                }
            } catch (error) {
                console.error("Error fetching search history:", error);
            }
        };

        fetchSearchHistory();
    }, []);

    return (
        <Layout title="History">
            <div
                className="md:pl-4 md:pr-10 w-full bg-no-repeat bg-cover"
                style={{ backgroundImage: "url('/assets/images/chart1.png')" }}
            >
                <section className="p-4 sm:p-6 w-full">
                    <h2 className="text-[20px] md:text-[24px] leading-[18px] font-bricolage font-semibold mb-8">
                        Search History
                    </h2>
                    {/* Table */}
                    <div className="bg-white relative overflow-x-auto">
                        <table className="w-full text-left font-dmSans text-md border-collapse">
                            <thead>
                                <tr>
                                    <th className="border border-gray-200 px-4 py-2 font-medium">Ticker</th>
                                    <th className="border border-gray-200 px-4 py-2 font-medium">Search Date</th>
                                    <th className="border border-gray-200 px-4 py-2 font-medium">Current Price</th>
                                    <th className="border border-gray-200 px-4 py-2 font-medium">Result</th>
                                </tr>
                            </thead>
                            <tbody>
    {searchHistory.length > 0 ? (
        searchHistory.map((search, index) => (
            <tr key={index} className="hover:bg-gray-50">
                <td className="border border-gray-200 px-4 py-2">{search.ticker}</td>
                <td className="border border-gray-200 px-4 py-2">
                    {new Date(search.searchDate).toLocaleDateString()}{" "}
                    {new Date(search.searchDate).toLocaleTimeString()}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                    <span className={index % 2 === 0 ? "text-[#DC3545]" : "text-[#00AC47]"}>
                        {search.currentPrice}
                    </span>
                </td>
                <td className="border border-gray-200 px-4 py-2">
                    <a href={`/result/${search.ticker}`} className="text-[#2A9D8F] hover:underline">
                        View
                    </a>
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="4" className="text-center py-4">
                No search history found.
            </td>
        </tr>
    )}
</tbody>

                        </table>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default HistoryPage;
