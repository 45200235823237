import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signupUser } from '../../utils/api';
import GoogleAuth from './GoogleAuth';
import Menu from "../Menu";

const Signup = () => {
  const [formData, setFormData] = useState({ email: '', password: '', name: '', phone: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!formData.email || !formData.password || !formData.name || !formData.phone){
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    try {
      const response = await signupUser(formData);
      alert(response.msg || 'User created successfully. Please verify your email.');
      navigate('/login');
    } catch (err) {
      if (err.response && err.response.data) {
        const msg = err.response.data.msg;

        if (msg.includes('Google Sign-In')) {
          setError('This email is linked to Google Sign-In. Please log in using Google.');
        } else if (msg.includes('already exists')) {
          setError('An account with this email already exists. Please log in.');
        } else {
          setError(msg || 'Failed to create an account. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-[#F3FCFC] px-4 py-12 md:py-20 mt-8"
      style={{
        backgroundImage: "url('/assets/images/hero_bg_image.png')",
      }}>
      <Menu />
      <div className="max-w-2xl text-center mb-12">
        <h1
          className="font-bricolage font-semibold text-[32px] leading-[40px] md:text-[46.4px] md:leading-[56px]"
          style={{
            background: 'linear-gradient(180deg, #2D3142 0%, #2A9D8F 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Create Your Account
        </h1>
        <p className="font-dmSans font-bold text-[16px] leading-[24px] md:text-[19.3339px] md:leading-[150%] text-[#2D3142] mt-4">
          Sign up to explore market trends, analyze key insights, and make confident decisions.
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-[32px] shadow-md p-6 md:p-8 w-full max-w-md"
      >
        <div className="mb-4">
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans mb-4"
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans mb-4"
          />
          
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="w-full bg-[#F3FCFC] border border-gray-300 rounded-[23px] px-4 py-3 text-sm md:text-base font-dmSans"
          />
        </div>
        {error && (
          <p className="text-red-500 text-sm md:text-base font-dmSans mb-4">
            {error}
          </p>
        )}
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-[#2A9D8F] text-white font-dmSans font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600"
        >
          {loading ? 'Creating account...' : 'Sign Up'}
        </button>
      </form>

      <div className="mt-6">
        <GoogleAuth />
      </div>

      <div className="mt-6 text-sm md:text-base font-dmSans text-[#2D3142]">
        Already have an account?{' '}
        <Link
          to="/login"
          className="text-[#2A9D8F] font-semibold hover:underline"
        >
          Log In
        </Link>
      </div>
    </div>
  );
};

export default Signup;