import React from 'react';
import { RiAiGenerate } from "react-icons/ri";

const StockSearchForm = ({ ticker, setTicker, handleGeneratePrediction, loading, error }) => {
  return (
    <div className="sm:flex md:block lg:flex gap-2 items-center  mb-16 w-full">
      <div className="flex flex-1 gap-2 items-center bg-[#F5F5F56B] rounded-3xl border border-[#2D3142]">
        {/* Input for Ticker Symbol */}
        <input
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())} // Ensure uppercase symbols
          className="px-3 py-2 focus:outline-none text-sm bg-transparent flex-1"
          placeholder="Enter symbol"
          disabled={loading} // Disable while loading
        />
        <div className="border-l border-dashed border-[#2D3142] px-4 py-3">
            <select className="text-sm focus:outline-none bg-transparent">
                <option value="1D">1D</option>
                <option value="1W">1W</option>
                <option value="1M">1M</option>
            </select>
        </div>
      </div>
        {/* Button to Generate Prediction */}
        <button
          onClick={handleGeneratePrediction}
          className={`bg-[#2A9D8F] text-white py-3 px-6 rounded-full font-dmSans  hover:bg-teal-600  flex items-center justify-center disabled:opacity-50 mt-4 sm:mt-0 md:mt-4 lg:mt-0 mx-auto ${
            loading ? 'animate-spin' : ''
          }`}
          disabled={loading} // Disable while loading
        >
          {loading ? 'Generating...' : 'Generate Prediction'}
          <RiAiGenerate color='#FFC107' size="22px" className='ml-2'/>
        </button>

        {/* Error Message */}
        {error && <p className="text-red-500 text-sm ml-4">{error}</p>}
      
    </div>
  );
};

export default StockSearchForm;
