import React, { useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from '../../context/AuthContext';
import { createCheckoutSession } from '../../utils/api';
import Menu from "../Menu";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Subscribe = () => {
  const { authState } = useContext(AuthContext);

  const handleSubscribe = async () => {
    const { id } = await createCheckoutSession(authState.token);
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId: id });
  };

  return (
    <div className="min-h-screen bg-[#F3FCFC]">
      {/* Menu */}
      <Menu />

      {/* Hero Section */}
      <section
        className="relative bg-cover bg-center bg-no-repeat py-16 md:py-24"
      >
        <div className="container mx-auto px-4 text-center">
          <h1
            className="font-bricolage font-semibold text-[32px] leading-[40px] md:text-[46.4px] md:leading-[56px]"
            style={{
              background: "linear-gradient(180deg, #2D3142 0%, #2A9D8F 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Unlock Advanced Features with Trenviz Pro
          </h1>
          <p className="font-dmSans font-bold text-[16px] leading-[24px] md:text-[19.3339px] text-[#2D3142] mt-4">
            Upgrade now to gain full access to our advanced tools, actionable insights, and enhanced predictions.
          </p>
        </div>
      </section>

      {/* Subscription Plans */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4 md:px-8 lg:px-36">
          <div className="text-center mb-12">
            <h2
              className="font-bricolage font-semibold text-[28px] leading-[34px] md:text-[35px] md:leading-[42px]"
              style={{
                background: "linear-gradient(180deg, #2D3142 0%, #1D7B6F 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Choose Your Plan
            </h2>
          </div>

          {/* Plan Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Basic Plan */}
            <div className="bg-[rgba(235,250,250,0.6)] rounded-[23px] p-6 md:p-8 text-center">
              <h3 className="font-bricolage font-bold text-[24px] leading-[30px] text-[#2D3142]">
                Free Plan
              </h3>
              <p className="font-dmSans font-medium text-[16px] leading-[22px] text-[#2D3142] mt-4">
                Limited access to basic features.
              </p>
              <p className="font-dmSans font-bold text-[20px] text-[#2A9D8F] mt-4">$0/month</p>
            </div>

            {/* Pro Plan */}
            <div className="bg-white border border-[#2A9D8F] rounded-[23px] p-6 md:p-8 text-center shadow-lg">
              <h3 className="font-bricolage font-bold text-[24px] leading-[30px] text-[#2D3142]">
                Pro Plan
              </h3>
              <p className="font-dmSans font-medium text-[16px] leading-[22px] text-[#2D3142] mt-4">
                Full access to all advanced features.
              </p>
              <p className="font-dmSans font-bold text-[20px] text-[#2A9D8F] mt-4">$19.99/month</p>
              <button
                onClick={handleSubscribe}
                className="mt-6 bg-[#2A9D8F] text-white font-dmSans font-semibold text-[14px] leading-[20px] md:text-[15.4671px] px-6 md:px-8 py-4 rounded-[32px] shadow-md hover:bg-teal-600"
              >
                Subscribe Now
              </button>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-[rgba(245,241,254,0.6)] rounded-[23px] p-6 md:p-8 text-center">
              <h3 className="font-bricolage font-bold text-[24px] leading-[30px] text-[#2D3142]">
                Enterprise Plan
              </h3>
              <p className="font-dmSans font-medium text-[16px] leading-[22px] text-[#2D3142] mt-4">
                Custom solutions for businesses.
              </p>
              <p className="font-dmSans font-bold text-[20px] text-[#2A9D8F] mt-4">Contact Us</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Subscribe;
