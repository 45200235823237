const ActiveHomeIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9427 3.05731C16.6926 2.80735 16.3535 2.66693 16 2.66693C15.6464 2.66693 15.3074 2.80735 15.0573 3.05731L5.72399 12.3906L3.05732 15.0573C2.92997 15.1803 2.8284 15.3274 2.75852 15.4901C2.68864 15.6528 2.65186 15.8277 2.65032 16.0048C2.64878 16.1818 2.68252 16.3574 2.74956 16.5213C2.8166 16.6851 2.9156 16.834 3.04079 16.9592C3.16598 17.0844 3.31485 17.1834 3.47871 17.2504C3.64258 17.3174 3.81815 17.3512 3.99519 17.3496C4.17223 17.3481 4.34719 17.3113 4.50986 17.2414C4.67253 17.1716 4.81966 17.07 4.94265 16.9426L5.33332 16.552V25.3333C5.33332 26.3942 5.75475 27.4116 6.50489 28.1617C7.25504 28.9119 8.27245 29.3333 9.33332 29.3333H22.6667C23.7275 29.3333 24.7449 28.9119 25.4951 28.1617C26.2452 27.4116 26.6667 26.3942 26.6667 25.3333V16.552L27.0573 16.9426C27.3088 17.1855 27.6456 17.3199 27.9952 17.3169C28.3448 17.3138 28.6792 17.1736 28.9264 16.9264C29.1736 16.6792 29.3138 16.3448 29.3169 15.9952C29.3199 15.6456 29.1855 15.3088 28.9427 15.0573L16.9427 3.05731Z"
                fill="#2A9D8F"
                fillOpacity="0.7"
            />
        </svg>
    );
};

export default ActiveHomeIcon;
