import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionId) {
      console.log('Stripe session ID:', sessionId);
      // Optionally fetch details or update the backend
    }
    const timer = setTimeout(() => {
      navigate('/dashboard'); // Redirect to the dashboard after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [sessionId, navigate]);

  return (
    <div
      className="bg-white py-16 md:py-24 text-black-100 mt-12 flex flex-col items-center justify-center"
      style={{
        backgroundImage: "url('/assets/images/hero_bg_image.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
      }}
    >
      <div className="bg-white shadow-lg rounded-lg p-8 sm:max-w-lg text-center">
        <h1 className="text-[30px] leading-[50px] md:text-[40px] lg:text-[54px] font-bold text-[#2A9D8F] mb-4 font-bricolage">
          Payment Successful!
        </h1>
        <p className="text-[#2D3142] font-dmSans mb-6">
          Thank you for upgrading your plan. Your payment was successful, and your subscription is now active.
        </p>
        <p className="text-gray-500 text-sm font-dmSans">
          Redirecting you to your dashboard in 5 seconds...
        </p>
        <div className="mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            width="100px"
            height="100px"
            className="mx-auto mb-6"
          >
            <circle cx="12" cy="12" r="10" fill="#2A9D8F" />
            <path
              d="M9 12.5l2.5 2.5 5-5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <button
            onClick={() => navigate('/dashboard')}
            className="bg-[#2A9D8F] text-white text-sm md:text-md px-8 py-4 rounded-full hover:bg-teal-600 transition font-dmSans"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
