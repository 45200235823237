import { useState } from "react";

const DeleteAccountModal = ({ isOpen, onClose, onConfirm }) => {
    const [confirmationText, setConfirmationText] = useState("");

    const handleConfirm = () => {
        if (confirmationText === "DELETE") {
            onConfirm();
        } else {
            alert("You must type DELETE to confirm.");
        }
    };

    return (
        isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-md">
                    <h2 className="text-lg font-semibold mb-4 text-red-600">
                        Delete Account
                    </h2>
                    <p className="text-sm text-gray-700 mb-4">
                        Deleting your account is permanent and cannot be undone. All your data will be lost.
                        Please type <span className="font-bold">DELETE</span> in the field below to confirm.
                    </p>
                    <input
                        type="text"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                        className="w-full border rounded-lg p-2 mb-4"
                        placeholder="Type DELETE to confirm"
                    />
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-300 rounded-lg"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirm}
                            className="px-4 py-2 bg-red-600 text-white rounded-lg"
                        >
                            Delete Account
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default DeleteAccountModal;
